.footer{
    background-color:#0e76bc;
    padding:40px 0px;
    color:white;
    font-size: 14px;
}

.footer a{
    color:#fff;
}

.footer a:hover{
    color:#7FCBFF;
}

footer h5{
    font-weight: bold;
    font-size:1.3em;
}

.footer-menu {
    list-style-type: none;
    padding: 0;
    margin-top: 20px ;
    font-size: 14px;
}

.footer-menu li{
    padding-bottom:5px;
}

.footerbar{
    background-color:#283891;
}

.footerbar-content{
    padding: 10px 0px;
    color:#fff;
    font-size: 14px;
}

.footerbar-content p{
    margin-bottom: 0 !important;
}

.privacy{
    text-align: right;
}

.privacy a{
    color:#fff;
}

.privacy a:hover{
    color:#7FCBFF;
}
    
.contact-footer{
    margin-top: 20px;
}
