* {
    font-family: 'Montserrat', sans-serif;
}
body{
    overflow-x: hidden;
}

.row{
    max-width: 1200px;
    margin-right: 0;
    margin-left: 0;
}

.center {
    text-align: center;
    margin: 0 auto;
}

.mrg {
    margin-bottom: 100px;
    margin-top: 100px;
}

.topbar{
    background-color: #283891;
    color:white;
    padding:20px 0;
    text-align: center;
}
.topbar a{
    color:white;
}

.topbar a:hover{
    color:#7FCBFF;
}

header {
    width: 100%;
    background-color: #0e76bc;
    font-weight: 500;
}

.navItem:not(:last-child) {
    margin-right: 20px;
}

.nodrop a{
    color:#fff;
    display: block;
    padding: .5rem 1rem;
}

@media screen and (max-width:991px){
    .nodrop a{
        padding: .5rem 0;
    }
}

.navItem:hover,
.navItem a:hover {
    color: #283891 !important;
    text-decoration: none;
}

.container {
    max-width: 1200px !important;
}

.dropdown-item.active, .dropdown-item:active{
    color:#283891 !important;
    background-color: white !important;
}

/**Containet fluid full**/
.full {
    padding: 0 !important;
}

.navbar.navbar-expand-lg.navbar-dark,
.navbar-brand {
    padding: 0 !important;
}

.logo {
    width: 120px;
    height: 100px;
    background-image: url("../imgs/logo.svg");
    background-position: center;
    background-size: cover;
    border-radius: 10px;
}


.nav-link {
    color: white !important;
}

#customLink {
    color: #0e76bc !important;
}
/** Main img**/
.imageNotSlider{
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-image: url("../imgs/home_image.jpg");
    box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.imageNotSlider h1{
    color:#fff;
    font-weight: bolder;
    letter-spacing: 1px;
    font-size: 34px;
}

.imageNotSlider h2{
    color:#fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.3em;
}

.buttonSlider{
    display: inline-block !important;
}

.buttonSlider .leftOne{
    margin-right: 20px;
}


@media screen and (max-width:605px){
    .imageNotSlider h1{
        font-size:24px
    }
    .imageNotSlider h2{
        font-size:16px
    }
    .buttonSlider .stockButton3{
        font-size:14px !important
    }
    .buttonSlider .leftOne{
        margin-right: 0;
    }
}

/** Slider **/
.homeSlider {
    position: relative;
}

.homeSliderTxt {
    position: absolute;
    top: 10%;
    background-color: #0e76bc;
    right: 25%;
    color: #fff;
    padding: 50px;
    border-radius: 20px;
}

.homeSliderTxt h3 {
    max-width: 350px;
    font-weight: 400;
    margin-bottom: 20px;
}

@media screen and (max-width:1023px) {
    .homeSliderTxt {
        display: none;
    }
}

/** Carousel **/
.carouselRow{
    justify-content: center;
    margin-bottom: 100px;
}

.carouselHome .carousel-indicators{
    bottom:-70px;
}

.carouselHome .carousel-indicators li{
    border-radius:50%;
    width:15px; height: 15px;
}

.carouselHome .carousel-control-prev{
    left:-70px;
    opacity: 1;
    background-color: #7FCBFF;
    width: 35px;
    height: 35px;
    top:85px;
    border-radius: 50%;
    }

.carouselHome .carousel-control-next{
    right:-70px;
    opacity: 1;
    background-color: #7FCBFF;
    width: 35px;
    height: 35px;
    top:85px;
    border-radius: 50%;
}


.carouselItem1:not(:last-child), 
.carouselItem2:not(:last-child),
.carouselItem3:not(:last-child){
    margin-right: 30px;
}

.carouselItem1,
.carouselItem2,
.carouselItem3{
    width: 200px;
    height: 200px;
}

@media screen and (max-width:810px){
.carouselItem1,
.carouselItem2,
.carouselItem3{
    width: 100px;
    height: 100px;
}
.carouselHome .carousel-control-prev,
.carouselHome .carousel-control-next{
    display: none;
}
}

@media screen and (max-width:390px){
.carouselItem1,
.carouselItem2,
.carouselItem3{
    width: 70px;
    height: 70px;
}
}

/** Home Page **/
.APP{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color:#23a455;
    color:#fff;
    padding: 20px 0;
}

.APP-shop{
    text-align: center;
    margin-bottom: 40px;
}

.APP-shop h3{
    font-size:20px;
}

.APP h3{
    font-size:20px
}

.google img{
    width:150px;
}

.apple img{
    width:120px;
}


.underCarousel {
    text-align: center;
    background-color: #283891;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0 !important;
}

.underA {
    padding: 10px 20px;
    background-color: #0e76bc;
    border-radius: 20px;
}

.underB {
    padding: 10px 20px;
    background-color: #283891;
    border-radius: 20px;
}

.home-info h2{
    margin-top: 20px;
    font-size: 18px ;
    font-weight: 600;
    text-align: center;
}

.mainNotice {
    padding:100px 0;
    text-align: center;
}

.mainNotice h4{
    font-size:16px;
    font-weight: 600;
    margin-bottom: 60px;
}

.mainNoticeIcon {
    font-size: 50px;
    color:#283891;
    margin-bottom: 20px;
}

.homeButtons {
    padding: 100px 0;
    text-align: center;
    background: url('../imgs/bg1.png') center center #283891;
    background-size: cover;
}

.stockButton {
    background-color: #fff;
    color: #0e76bc;
    padding: 15px 30px;
    border: solid 2px #fff;
    border-radius: 5px;
    font-weight: 600;
    text-transform: uppercase;
}

.stockButton2 {
    margin-top: 50px;
    background-color: #283891;
    color: #fff;
    padding: 15px 30px;
    border: solid 2px #283891;
    border-radius: 5px;
    font-weight: 600;
    text-transform: uppercase;
}

.stockButton:hover {
    color: #fff;
    background-color: #0e76bc;
    transition: 0.3s ease-in-out;
}

.stockButton2:hover {
    color: #283891;
    background-color: #fff;
    transition: 0.3s ease-in-out;
}


.howItWorks {
    padding: 100px 0;
    background: #d4d9f3;
    text-align: center;
}

.mainHow {
    text-align: center;
    color: #283891;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 30px;
}

.mainHowDesc {
    color: #283891;
    text-align: center;
    margin: 20px 0px 40px;
    font-size: 18px;
}

.mainIcon {
    display: block;
    background-color: #fff;
    padding: 80px 0px;
    border-radius: 20px;
    margin: 0 auto;
    max-width: 350px;
    min-height: 415px;
    box-shadow: rgba(0, 0, 0, 0.25) 5px 5px 15px;
}


@media screen and (max-width:990px) {

    .mainIcon,
    .homeFix {
        margin-bottom: 60px;
    }
}


/** Kopen **/

.kopenHeading {
    margin-top: 80px;
    color: #283891;
    font-weight: bold;
}

.kopenTitle {
    padding: 10px 30px;
    background-color: #283891;
    color: #fff;
    font-weight: bold;
    border-radius: 20px;
    text-align: center;
    margin-bottom: -20px;
    font-size:18px;
    margin-top:40px
}

.kopenLight {
    background-color: #7FCBFF;
    padding: 100px 0 !important;
    margin: 0;
}

.kopenDark {
    background-color: #283891;
    color: white;
    text-align: center;
    margin: auto;
    padding: 0 !important;
}

.kopenHowItemLight {
    background-color: #0e76bc;
    padding: 40px 0;
}

.kopenHowItemDark {
    background-color: #283891;
    padding: 40px 0;
}

.kopenHowItemNumber {
    margin: 10px auto;
    width: 30px;
    height: 30px;
    background-color: #fff;
    color: #283891;
    border-radius: 50%;
    font-weight: bold;
    font-family: 'Montserrat';
    padding-top: 3px;
}

.kopenHowItemName {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}

.small-letters {
    font-size: 11px;
}

.kopenBieden {
    margin-top: 100px;
    margin-bottom: 100px;
}

.kopenBieden h3 {
    color: #283891;
    font-weight: bold;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-size: 24px;
}

.kopenBieden p {
    color: #283891;
    text-align: justify;
}

.kopenNewsletter {
    background-color: #7FCBFF;
    margin: auto;
    padding: 100px 10px;
    border-radius: 20px;
    text-align: center;
}

.kopenFix {
    padding-right: 40px !important;
}

@media screen and (max-width:990px) {
    .kopenNewsletter {
        margin-top: 100px !important;
    }

    .kopenFix {
        padding-right: 15px !important;
    }
}

/**Verkopen**/
.verkopenPromo {
    background-color: #283891;
    border-radius: 20px;
    padding: 20px 0;
}

.verkopenPromo h3,
.verkopenPromo p {
    color: #fff;
}

.verkopenPromoTitle {
    text-align: center;
    padding: 10px 20px;
    background-color: #0e76bc;
    border-radius: 20px;
}

.blueorange {
    height: 300px;
    width: 100%;
    background: url("../imgs/blueorange.jpg") center;
    background-size: cover;
    border-radius: 20px;
    margin-bottom: 20px;
}

/**Promotipakket**/
.promoContent {
    margin: 60px 0;
}

.promoHL p {
    background: url('../imgs/bg1.png') center center #7fccff57;
    text-align: center;
    color: #283891;
    font-size: 24px;
    font-weight: bold;
    padding: 20px 0;
    margin: 0px !important;
}

/**Over Ons**/
.overHeading {
    margin-top: 100px;
    margin-bottom: 100px;
}

.overTitle {
    color: #283891;
    font-weight: bold;
    font-size: 44px;
    margin-bottom: 20px;
}

.overText {
    text-align: justify;
    margin-bottom: 20px;
}

.overText2 {
    text-align: justify;
}

.green {
    color: #23a455;
    font-weight: bold;
}

.overRow {
    margin-top: 100px;
    margin-bottom: 40px;
}

.overBlue {
    color: #283891;
    font-weight: bold;
}

.overImg {
    text-align: center;
}

.overWaarom {
    font-weight: bold;
    color: #283891;
    text-align: left;
    margin-bottom: 20px;
}

.slogan {
    margin-top: 40px;
    font-style: italic;
}

.overHL {
    background: url('../imgs/bg1.png') center center #7fccff57;
    text-align: center;
    padding: 100px 0;
}

.overHelpTitle {
    font-weight: bold;
    color: #283891;
    margin-bottom: 20px;
    font-size: 34px;
}

.overHL p {
    color: #23a455;
    font-weight: 500;
}

.overHL h4 {
    font-weight: 500;
    color: #283891;
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 0 !important;
}

.overKortom {
    margin-top: 100px;
    margin-bottom: 100px;
}

.overKortom h3 {
    color: #283891;
    margin-bottom: 40px;
}

.overKortom h5 {
    color: #283891;
    font-weight: 400;
    margin-bottom: 20px;

}

.overKortom p {
    color: #23a455;
    margin-bottom: 40px;
}

.overKortom h1 {
    color: #283891;
}

.smallcp {
    font-size: 18px;
    vertical-align: super;
}

.teamTitle {
    padding: 10px 30px;
    background-color: #283891;
    color: #fff;
    font-weight: bold;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 60px;
}

.teamItem h4 {
    color: #283891;
    text-transform: uppercase;
    font-weight: bold;
    margin: 20px 0 10px;
}

.teamItem h6 {
    color: #283891;
    font-weight: 300;
    text-transform: uppercase;
}

.teamItem p {
    color: #23a455;
    font-weight: 300;
    margin: 20px 0;
}

.overSocial a {
    color: #0e76bc;
    font-size: 24px;
    margin-right: 20px;
}

.overSocial a:hover {
    color: #23a455;
}

/** Nieuws **/

.nieuwsTitle {
    text-align: center;
    margin-bottom: 100px;
    text-transform: uppercase;
    font-weight: bolder;
    color: #283891;
}

.nieuwsStar {
    color: rgb(197, 168, 0);
    font-size: 24px;
    margin-top: 20px;
}

.nieuwsContent {
    background-color: #7FCBFF;
    padding: 100px 0;
}

.nieuwsCol {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
}

.nieuwsCol img {
    display: inline;
    padding: 0 20px;
}

.nieuwsCol h2 {
    color: #fff;
    font-weight: bold;
    margin-bottom: 20px;
}

.nieuwsCol h3 {
    display: inline;
    margin-left: 20px;
    font-size: 24px;
    color: #fff;
    font-style: italic;
}

.nieuwsCol h5 {
    color: #fff;
    text-transform: uppercase;
    margin-top: 20px;
}

.nieuwsNews {
    background-color: #283891;
    border-radius: 0 0 20px 20px;
    margin-top: 20px;
    padding: 20px 0;
}

.nieuwsIcon {
    color: #fff;
    font-size: 24px;
}

.pitch {
    margin-top: 100px;
    margin-bottom: 100px;
    border: double 13px #283891;
    padding: 20px;
}

.pitch h2 {
    text-align: center;
    color: #283891;
    font-weight: bold;
    margin-bottom: 40px;
}

.video div {
    margin: 0 auto 60px;
    max-width: 100%;
}

.pitch p {
    color: #283891;
}

.pitchImg {
    text-align: center;
    margin-top: 60px;
}

.pitchImg img {
    border-radius: 100%;
    width: 200px;
    height: 200px;
    object-fit: cover;
    margin-bottom: 20px;
}

.pitchImg h5 {
    text-transform: uppercase;
    font-weight: bold;
    color: #283891;
}

.pitchImg h6 {
    color: #283891;
}

.pitchRegister {
    padding: 100px 0;
    text-align: center;
    background: url('../imgs/bg1.png') center center #7fccff57;
    background-size: cover;
    color: #283891;
}

.pitchRegister h2 {
    font-weight: bold;
}

/**-----------------Shop-------------------**/

.shopTitle {
    text-align: center;
    color: #283891;
    font-size: 16px !important;
    font-weight: bold !important;
    height: 90px;
}

.shopImg{
    max-width: 100%;
    height: 300px;
}

.shopCat {
    text-align: center;
    color: #7FCBFF;
    font-size: 14px !important;
    margin-bottom: 20px;
}

.shopItem {
    display: inline-block;
    vertical-align: top;
    width: 32%;
    margin-bottom: 50px;
    box-shadow: 3px 3px 15px 0px rgba(65, 65, 65, 0.16);
    padding: 20px;
    margin-right: 15px;
}

.shopCard {
    text-align: center;
}

.shopCard img{
    object-fit: cover;
}

.shopButton {
    margin-top: 20px;
    background-color: #283891;
    color: #fff;
    font-weight: bold;
    padding: 15px 30px;
    border: solid 2px #283891;
    border-radius: 5px;
}

.shopButton:hover {
    background-color: #fff;
    color: #283891;
}

.shopTimer {
    font-weight: bold;
    background-color:#c47500;
    padding:7px 0;
    color:#fff;
    border-radius: 20px;
    margin-bottom: 20px;
}

.closed{
    filter:saturate(0)
}

@media screen and (max-width:1155px) {
    .shopItem {
        width: 48%;
    }
}

@media screen and (max-width:778px) {
    .shopItem {
        width: 100%;
        margin-right: 0;
    }
}

/**-------------------Contact--------------------------**/
.contactTitle {
    padding: 10px 30px;
    background-color: #283891;
    color: #fff;
    font-weight: bold;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 40px;
}

.contactBlock1 {
    background-color: #d4d9f3;
    padding: 40px;
    margin-bottom: 30px;
}

.contactPersonImg,
.contactPersonText {
    display: inline-block;
    vertical-align: middle;
}

.contactPersonImg {
    margin-right: 30px;
}

.contactImg {
    width: 200px;
}

.contactPersonText p {
    margin-bottom: 5px;
    color: #283891;
}

.contactPerson2 {
    margin-top: 40px
}

.mapouter {
    position: relative;
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
}

.contactMapTitle {
    padding: 10px 30px;
    background-color: #283891;
    color: #fff;
    font-weight: bold;
    border-radius: 20px;
    text-align: center;
}

.contactBlock3 {
    background-color: #d4d9f3;
    padding: 40px;
}

.contactBlock3 h3 {
    text-align: center;
    color: #283891;
    font-weight: bold;
    font-size: 22px;
}

.contactAdress h3 {
    font-weight: bold;
    color: #283891;
    margin-bottom: 20px;
}

.contactAdress p {
    margin-bottom: 5px;
    color: #283891;
}

.contactAdress h5 {
    color: #283891;
    margin-top: 20px;
    font-weight: bold;
}

.contactSocial:not(:last-child) {
    margin-right: 20px;
}

.contactSocial a {
    font-size: 30px;
    color: #283891;
}

.contactSocial a:hover {
    color: #0e76bc;
}

.contact-margin {
    margin-bottom: 100px;
}

/**--------------- Product -------------**/
.prodMainImg{
    width:100%;
    height: 500px;
    object-fit: cover;
}

.prodName {
    font-weight: bold;
    font-size: 24px;
    color: #283891;
    margin-bottom: 40px;
}

.prodDesc {
    color: #283891;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.prodTime{
    margin-bottom: 30px;
}


.prodCat,
.prodTime {
    color: #0E76BC;
    font-weight: bold;
    font-size: 18px;
    display: inline-block;
}

.prodHigh{
    border:solid 1px #283891;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.prodMax{
    margin-bottom: 20px;
}

.prodMax,
.prodRetail{
    display: inline-block;
    color:#283891;
    font-weight: bolder;
}

.prodValue{
    font-weight: 500;
    margin-left: 5px;
}

.prodBid{
    color:#283891;
    font-weight: bolder;
    font-size:24px
}

.prodGalleryItem{
    height:500px;
    object-fit: contain;
    margin-bottom: 20px;
}

.prodGallery .carousel-indicators li{
    background-color: #283891;
    border-radius: 50%;
    width:7px;
    height: 7px;
}

.prodGallery .carousel-indicators{
    bottom: -23px;
}

.prodGallery .carousel-control-next{
    background: linear-gradient(90deg, rgba(14,118,188,1) 0%, rgba(40,56,145,1) 100%);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    top:45%;
    right:0;
    opacity: 0.75;
}

.prodGallery .carousel-control-prev{
    background: linear-gradient(90deg, rgba(40,56,145,1) 0%, rgba(14,118,188,1) 100%);
    border-radius: 50%;
    height: 50px;
    width: 50px;
    top:45%;
    left: 0;
    opacity:0.75;
}

.bidButton{
    background-color: #283891;
    color: white;
    border: solid 2px #283891;
    border-radius: 3px;
    padding: 10px 30px;
    margin-bottom: 20px;
    width: 50%;
    font-weight: 500;
}

.bidButton:hover{
    color:#283891;
    background-color: white;
}

.bidValue{
    margin-bottom:10px !important;
    width: 50%;
}

.handlingTransport{
    font-size: 15px;
}
.handlingTransport span{
    font-weight: bold;
    font-size: 24px;
}

.BTW{
    font-size: 13px;
    color:red;
}

.prodFav{
    color:#283891;
    font-weight: bold;
}

.prodFav:hover{
    cursor: pointer;
}

.helpButton{
    font-size: 20px;
    margin-bottom: 20px;
    color:#283891;
    font-weight: bold;
    cursor: pointer;
}

/** Aanbieden **/

.aanbiedenForm label:not(.fixForm){
    display: block;
    color:#283891;
    font-weight: bold;
    margin-bottom: 30px;
}

.aanbiedenForm{
    margin:60px 0;
}

.fixForm{
    display:block;
    color:#283891;
    font-weight: bold;
    margin-bottom: 10px;
}


.inputForm{
    margin-top:5px;
    width:70%;
    border:none;
    border-bottom: solid 1px #283891;
    padding-left: 10px;
}

.radioValue{
    color:#283891;
    margin-right: 30px;
    display: inline-block !important;
}

.stockButton3{
    margin-top: 20px;
    background-color: #283891;
    color: #fff;
    padding: 15px 30px;
    border: solid 2px #283891;
    border-radius: 5px;
    font-weight: 600;
    text-transform: uppercase;  
}

.stockButton3:hover {
    color: #283891;
    background-color: #fff;
    transition: 0.3s ease-in-out;
}




input:focus {outline:none}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

.aanbiedenText{
    background-color: #7FCBFF;
    padding:40px;
    border-radius: 20px;
}

.aanbiedenText h1{
    color: #283891;
    font-weight: bold;
    margin-bottom: 40px;
}

.aanbiedenText p{
    color:#283891;
    margin-bottom: 5px;
}

.aanbiedenWS{
    text-align: center;
    margin:40px 0;
}

.aanbiedenWS a{
    color:#37e257;
    font-size: 80px;
}

.aanbiedenWS h3{
    color:#283891;
    font-weight: bold;
}

.aanbiedenWaarom{
    position: relative;
    background-color: #0e76bc;
    padding:100px 10px 20px 25px;
    color:#fff;
    border-radius: 20px;
    margin-bottom: 100px;
}

.aanbiedenWaarom h4{
    background-color: #283891;
    position: absolute;
    top:25px;
    border-radius: 5px;
    padding: 15px;
}

.aanbiedenList{
    list-style-type: none;
    padding-left:15px;
}

.aanbiedenList li{
    margin-bottom: 15px;
}

.aanbiedenList svg{
    margin-right: 10px;
}



/** Login **/
.loginTitle{
    text-align: center;
    margin-bottom: 40px;
    font-size: 34px;
    font-weight: bolder;
    color: #283891;
}

.loginForm{
    border: solid 1px #0E76BC;
    text-align: center;
    margin:0 auto;
    padding: 80px 20px;
    max-width: 500px;
    border-radius: 20px;
}



.loginForm label{
    text-align: left;
    font-weight: bold;
    color: #0E76BC;
    margin-bottom: 50px;
}

.loginInput{
    width: 300px;
    margin-top: 5px;
    border-width:0px 0px 2px 0px;
    border-color: #0E76BC;
    padding: 0 5px ;
    color:#283891;
    font-weight: 500;
}

.loginButton{
    background-color:#0E76BC;
    color:white;
    font-weight: bold;
    border: solid 2px #0E76BC;
    padding:10px 30px;
    width:300px;
    border-radius: 20px;
}

.loginButton:hover{
    background-color:#fff;
    color:#0E76BC;
    transition: 0.3s ease-in-out;
}
.registerButton{
    background-color:#283891;
    color: white;
    font-weight: bold;
    border: solid 2px #283891;
    padding:10px 15px;
    font-size:13px;
    border-radius: 20px;
}
.registerButton:hover{
    background-color:#fff;
    color:#283891;
    transition: 0.3s ease-in-out;
}

.loginAgree{
    font-size:12px
}

/** MyAccount **/

.accountBlock{
    border-radius: 20px;
    border:1px solid #283891;
    padding: 40px 0;
    margin-bottom: 50px;
}

.accountTitle{
    text-align: center;
    margin-bottom: 50px;
    color:#0E76BC;
}

.accountTitle span{
    text-transform: uppercase;
    font-weight: bold;
    color:#283891;
}

.accountForm{
    text-align: center;
}

.accountSub{
    color:#283891;
    margin-bottom: 30px;
    font-weight: bold;
}

.accountChange label{
    color:#0E76BC;
    font-weight: 500;
}

.accountInput{
    margin-bottom: 30px;
}

.accountAuctions{
    border-collapse: collapse;
    width: 100%;
}

.accountAuctions td, .accountAuctions th{
    border: 1px solid #ddd;
    padding: 8px;
}

.accountAuctions th{
    background-color: #0E76BC;
    color:white;
    padding:12px 0;
}

.accountAuctions tr:nth-child(even){
    background-color: #f2f2f2;
}


/** Terms **/

.termsTitle{
    font-size:30px;
    text-align: center;
    color:#283891;
    font-weight: bold;
}


/** Loading **/
.fullScreen{
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sk-folding-cube {
    margin: 20px auto;
    width: 50px;
    height: 50px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
  }
  
  .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1); 
  }
  .sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #283891;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
            animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  .sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
            transform: scale(1.1) rotateZ(90deg);
  }
  .sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
            transform: scale(1.1) rotateZ(180deg);
  }
  .sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
            transform: scale(1.1) rotateZ(270deg);
  }
  .sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  .sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s; 
  }
  .sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
  }
  @-webkit-keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
              transform: perspective(140px) rotateX(-180deg);
      opacity: 0; 
    } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
              transform: perspective(140px) rotateX(0deg);
      opacity: 1; 
    } 90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
              transform: perspective(140px) rotateY(180deg);
      opacity: 0; 
    } 
  }
  
  @keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
              transform: perspective(140px) rotateX(-180deg);
      opacity: 0; 
    } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
              transform: perspective(140px) rotateX(0deg);
      opacity: 1; 
    } 90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
              transform: perspective(140px) rotateY(180deg);
      opacity: 0; 
    }
  }